<template>
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">跟单列表</h5>
        </a-col>
        <a-col :span="24" :md="12">
          <a-row type="flex" justify="end">
            <a-col :span="6" style="display: flex; align-items: center; justify-content: flex-end">
              <a-radio-group v-model="authorsHeaderBtns" size="small">
                <a-radio-button value="all">全部</a-radio-button>
                <a-radio-button value="online">进行中</a-radio-button>
                <a-radio-button value="stop">已结束</a-radio-button>
              </a-radio-group>
            </a-col>
            <a-col :span="6" style="display: flex; align-items: center; justify-content: flex-end">
              <a-button type="primary" icon="plus" size="small" @click="addtask()">
                创建新跟单
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="filteredData" :pagination="{pageSize: 10,}">

      <template slot="info" slot-scope="info">
        <div class="table-avatar-info">
          <a-avatar shape="square" :src="info.trader_platform"/>
          <div class="avatar-info">
            <h6>{{ info.uniqueName }}</h6>
            <p style="display: inline">任务ID：{{ info.id }}</p>
<!--            <p v-if="info.role_type === 2" style="display: inline;background-color: #f7d941;color: white;border-radius: 2px;margin-left: 5px">&nbsp;普&nbsp;</p>-->
            <p v-if="info.posSide_set === 2" style="display: inline;background-color: #ec575b;color: white;border-radius: 2px;margin-left: 5px">&nbsp;反&nbsp;</p>
<!--            <p v-if="info.lever_set === 2" style="display: inline;background-color: #4190f7;color: white;border-radius: 2px;margin-left: 5px">&nbsp;自&nbsp;</p>-->
            <p v-if="info.is_smart" style="display: inline;background-color: #4190f7;color: white;border-radius: 2px;margin-left: 5px">&nbsp;智&nbsp;</p>
          </div>
        </div>
      </template>

      <template slot="api" slot-scope="api">
        <a-tag class="tag-status" :class="api.flag === '1' ? 'ant-tag-primary' : 'ant-tag-success'">
          {{ api.api_name }}
        </a-tag>
      </template>

      <template slot="pnl" slot-scope="pnl">
        <span :style="{ color: pnl >= 0 ? '#31bd65' : '#eb4b6d', 'font-weight': '600' }">{{ pnl }} USDT</span>
      </template>

      <template slot="status" slot-scope="status">
        <a-tag class="tag-status" :class="status === 1 ? 'ant-tag-success' : 'ant-tag-muted'">
          {{ status === 1 ? "进行中" : "已结束" }}
        </a-tag>
      </template>

      <template slot="editBtn" slot-scope="row">
        <a-button type="link" :data-id="row.key" class="btn-edit" @click="detail(row.key)">
          查看详情
        </a-button>
      </template>

      <template slot="stopBtn" slot-scope="row">
        <a-button type="link" :data-id="row.key" class="btn-edit" @click="stopTask(row.key)">
          {{ row.status === 1 ? "终止跟单" : "" }}
        </a-button>
      </template>

    </a-table>
  </a-card>
</template>
<script>
import http from "../utils/http"


const columns = [
  {
    title: '跟单交易员',
    dataIndex: 'info',
    scopedSlots: {customRender: 'info'},
  },
  {
    title: 'API名称',
    dataIndex: 'api',
    scopedSlots: {customRender: 'api'},
  },
  {
    title: '跟单收益',
    dataIndex: 'pnl',
    scopedSlots: {customRender: 'pnl'},
  },
  {
    title: '创建时间',
    dataIndex: 'create_datetime',
    class: 'text-muted',
  },
  {
    title: '任务状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '',
    scopedSlots: {customRender: 'editBtn'},
    width: 50,
  },
  {
    title: '',
    scopedSlots: {customRender: 'stopBtn'},
    width: 50,
  },
];

// 数据结构转换
function transformItem(item) {
  let imagePath;
  let is_smart;
  if (item.trader_platform === 1) {
    if (item.role_type === 2 && item.follow_type === 2) {
      is_smart = true;
    }
  } else if (item.follow_type === 2) {
    is_smart = true;
  }
  switch(item.trader_platform) {
    case 1:
      imagePath = 'images/okx.png';
      break;
    case 2:
      imagePath = 'images/binance.png';
      break;
    case 3:
      imagePath = 'images/bicoin.png';
      break;
    case 4:
      imagePath = 'images/img-logo.png';
      break;
      // 可以添加更多的条件
    default:
      imagePath = 'images/okx.png'; // 默认图片
  }
  return {
    key: String(item.id), // 使用项目的 'id' 字段
    info: {
      trader_platform: imagePath,//'images/okx.png',
      uniqueName: item.uniqueName,
      id: String(item.id),
      posSide_set: item.posSide_set,
      lever_set: item.lever_set,
      role_type: item.role_type,
      follow_type: item.follow_type,
      is_smart: is_smart
    },
    api: {
      api_name: item.api_name,
      flag: item.flag,
    },
    pnl: parseFloat(item.pnl).toFixed(2),
    status: item.status,
    create_datetime: item.create_datetime.replace('T', '  ').substring(2, 20).replaceAll('-', '/'),
    // api: item.api,
  };
}

export default {
  data() {
    return {
      tableData: [],
      columns,
      authorsHeaderBtns: 'online',
    };
  },
  computed: {
    filteredData() {
      if (this.authorsHeaderBtns === 'all') {
        return this.tableData;
      }
      const statusToFilter = this.authorsHeaderBtns === 'online' ? 1 : 2;
      return this.tableData.filter(item => item.status === statusToFilter);
    }
  },
  methods: {
    async fetchData() {
      try {
        const token = localStorage.getItem("token");
        const response = await http.get("/api/taskadd/?token=" + token);
        const originalData = response.data.data;
        const tableData = originalData.map(item => transformItem(item));
        this.tableData = tableData; // 更新组件的数据属性
        // console.log(response.data.data); // 现在这里将正确打印出数据
      } catch (error) {
        console.error("请求失败:", error);
      }
    },
    async stopTask(id) {
      this.$confirm({
        title: '确认终止',
        content: '终止任务不会进行平仓，当前如有持仓，后续请自行平仓。',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          try {
            await http.patch("/api/taskadd/" + id + "/?token=" + localStorage.getItem("token"), {"task_id": id,"status": "2"});
            // console.log(response.data); // 处理响应
            this.fetchData(); // 更新数据
            // try {
            //   await http.patch('/api/apiadd/' + api + '/?token=' + localStorage.getItem("token"), {"status": 1,});
            // } catch (patchError) {
            //   console.error("更新状态时发生错误:", patchError);
            //   // 处理 http.patch 请求的错误
            // }
          } catch (error) {
            console.error('更新失败:', error); // 错误处理
          }
        },
        onCancel() {
          console.log('终止操作已取消');
        },
      });
    },
    addtask() {
      this.$router.push("/addtask");
    },
    detail(id) {
      // 导航到 tradedetails 页面
      this.$router.push({ name: '交易详情', params: { id } });
    },
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style lang="scss">
.ant-table-pagination.ant-pagination {
  margin-right: 25px;
  margin-left: 25px;
}
</style>